<template>
  <div id="app">
    <div class="filter-panel">
      <CSSelect>
        <select v-model="buildingId">
          <option value="" disabled>请选择楼栋</option>
          <option
            v-for="(item, index) in building"
            :key="item + index"
            :value="item.code"
          >
            {{ item.name }}
          </option>
        </select>
      </CSSelect>
      <button
        class="btn btn-primary"
        style="margin-left: 10px"
        @click="queryRoomList(RoomTentStatus.ALL)"
      >
        查询
      </button>
    </div>

    <h3 style="margin-top: 20px">
      <span class="nav_1"> 空置面积 (m²) : {{ noRentRoomSpace || "-" }} </span>
      <span class="nav_1"> 在租面积 (m²) : {{ rentRoomSpace || "-" }} </span>
      <span class="nav_1">
        出租率 :
        <template v-if="noRentRoomSpace || rentRoomSpace">
          {{
            ((rentRoomSpace / (noRentRoomSpace + rentRoomSpace)) * 100).toFixed(
              2
            )
          }}%
        </template>
        <template v-else>-</template>
      </span>
    </h3>

    <div class="nav_">
      <button
        :class="['navBar', tabBar == RoomTentStatus.ALL ? 'color' : 'color_1']"
        @click="changeTabBar(RoomTentStatus.ALL)"
      >
        全部房间({{ roomTotal || "-" }})
      </button>
      <button
        :class="[
          'navBar',
          tabBar == RoomTentStatus.NO_RENT ? 'color' : 'color_1',
        ]"
        @click="changeTabBar(RoomTentStatus.NO_RENT)"
      >
        空置房间({{ noRentRoomCount || "-" }})
      </button>
      <button
        :class="['navBar', tabBar == RoomTentStatus.RENT ? 'color' : 'color_1']"
        @click="changeTabBar(RoomTentStatus.RENT)"
      >
        在租房间({{ rentRoomCount || "-" }})
      </button>
    </div>

    <template v-for="floor in res">
      <div :key="floor.floor" v-if="floor.rooms && floor.rooms.length > 0">
        <!-- 卡片视图 -->
        <el-card class="topbackgauge">
          <span>{{ floor.floor }}层</span>
        </el-card>
        <div>
          <div
            v-for="room in floor.rooms"
            :key="room.roomId"
            :class="['box', room.rented||room.isTenant ? 'orgin' : '']"
            @click="lookRoomInfo(room)"
          >
            <p>房间编号 : {{ room.roomNo || "-" }}</p>
            <p>建筑面积 (m²) : {{ room.space || "-" }}</p>
            <p>租客信息 : {{ room.lesseeCompany || room.relevanceLesseeName || "-" }}</p>
            <p>合同时间 : {{ room.contractDuration || "-" }}</p>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import "element-ui/lib/theme-chalk/index.css";
import { RoomTentStatus } from "../../common/commonConst";
import CSSelect from "@/components/common/CSSelect";
import {BigNumber} from "../../src/utils/bignumber.js";
// 导入请求接口
import {
  stateRoomUrl,
  queryBuildingUrl,
  roomStateDetailUrl,
  pactDetailsUrl,
  ShowOwnerRoom,
  DefaultRentControlUrl, //默认租控图列表  最新添加的楼栋
} from "@/requestUrl";

export default {
  name: "roomState",
  props: {},
  components: {
    CSSelect,
  },
  created() {
    window.addEventListener("keydown", this.RoomStateDown);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.RoomStateDown);
  },
  deactivated(){
     window.removeEventListener("keydown", this.RoomStateDown);
  },
  data() {
    return {
      RoomTentStatus,
      noRentRoomSpace: 0,
      rentRoomSpace: 0,
      charge: [],

      // 房间名称的id
      regionId: "",

      // 全部房间的数量
      roomTotal: 0,
      // 空置房间的数量
      noRentRoomCount: 0,
      // 在租房间的数量
      rentRoomCount: 0,
      // 房间的所有信息信息
      res: [],
      // 楼栋ID
      buildingId: "",
      // 请选择楼栋
      building: [],
      //  楼层信息
      floors: [],
      value: "",
      // 出租状态
      rented: false,
      tabBar: RoomTentStatus.ALL,
    };
  },
  // 事件函数
  methods: {
    //默认默认租控图
    DefaultRentControl() {
    //   this.$fly.post(DefaultRentControlUrl, {
    //     regionCode: this.$vc.getCurrentRegion().communityId, //区域编码
    //     state: 1, //state
    //   }).then(res => {
    //       console.log('王');
    //       console.log(res);
    //   });
        this.queryRoomList();
    },
    RoomStateDown(e) {
      if (e.keyCode == 13) {
        this.queryRoomList(this.RoomTentStatus.ALL);
      }
    },
    changeTabBar(status) {
      this.tabBar = status;
      this.queryRoomList(status);
    },
    lookRoomInfo(info) {
      this.$router.push({ name: "RoomInfo", params: { roomId: info.roomId } });
    },
    // 获取组控图详情信息
    queryRoomDetail(roomId) {
      return new Promise((resolve) => {
        this.$fly
          .post(roomStateDetailUrl, {
            regionId: this.$vc.getCurrentRegion().communityId,
            roomId: roomId,
            operatorId: this.$vc.getCurrentStaffInfo().userId,
          })
          .then((res) => {
            resolve(res);
          });
      });
    },
    /**
     * 获取房间列表
     * @param {Number} status 查询的房间状态
     *  */
    queryRoomList(status = RoomTentStatus.ALL) {
      console.log(this.buildingId);
      this.$fly
        .post(stateRoomUrl, {
          regionId: this.$vc.getCurrentRegion().communityId,
          buildingId: this.buildingId,
          state: 1,
        })
        .then((response) => {
          this.roomTotal = 0;
          this.rentRoomSpace = 0;
          this.rentRoomCount = 0;
          this.noRentRoomCount = 0;
          this.noRentRoomSpace = 0;
          const floorList = [];

          response.data.forEach((floor) => {
            let roomList = [];
            floor.rooms.forEach((room) => {
              switch (status) {
                case RoomTentStatus.ALL:
                  roomList.push(room);
                  break;
                case RoomTentStatus.RENT:
                  if (room.rented || room.isTenant) {
                    roomList.push(room);
                  }
                  break;
                case RoomTentStatus.NO_RENT:
                  if (!room.rented && !room.isTenant) {
                    roomList.push(room);
                  }
                  break;
              }
              this.roomTotal++;
              if (room.rented || room.isTenant) {
                this.rentRoomSpace += room.space;
                this.rentRoomCount++;
                return;
              }
              this.noRentRoomCount++;
              let a = BigNumber(this.noRentRoomSpace);
              this.noRentRoomSpace = this.add(this.noRentRoomSpace,room.space);
              // this.noRentRoomSpace =a.plus(room.space);
            });
            floorList.push({
              floor: floor.floor,
              rooms: roomList,
            });
          });
          this.res = floorList;
        });
    },
    add(a, b) {
      a = BigNumber(a);
      b = BigNumber(b);
      return a.plus(b).toNumber();   //结果需要用toNumber转为普通数字
    },
    // 获取楼栋信息
    queryBuilding() {
      return new Promise((resolve) => {
        this.$fly
          .post(queryBuildingUrl, {
            id: "",
            code: "",
            name: "",
            regionCode: this.$vc.getCurrentRegion().communityId,
            pageNo: 1,
            pageSize: 50,
            state: 1,
          })
          .then((res) => {
            this.building = res.data.datas;
            resolve(res.data);
          });
      });
    },
  },

  async mounted() {
    const x = await this.queryBuilding();
    this.buildingId=this.building[0].code;
    this.queryRoomList();
  },
};
</script>

<style lang="css" scoped>
body {
  background-color: #ffffff;
}
i {
  font-style: normal;
}

.query {
  margin-left: 10px;
  background-color: #1ab394;
  color: #ffffff;
}
.nav_1 {
  font-size: 24px;
  margin: 20px 50px 20px 0;
  color: #000
}
.navBar {
  width: 150px;
  height: 40px;
  color: #ffffff;
  border: 0;
}
/* 房间信息 */
.box {
  display: inline-block;
  width: 270px;
  background-color: #1ab394;
  border-radius: 5px;
  color: #ffffff;
  padding: 10px;
  overflow: hidden;
  margin: 5px;
}

.box p {
  margin-bottom: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.box p:not(:last-of-type) {
  margin-bottom: 10px;
}

.message_ {
  display: block;
  margin-top: 10px;
}
.orgin {
  background-color: #ff9f00;
}
.el-card {
  margin: 20px 0;
}
.color_1 {
  background-color: #6c757e;
  border-left: 0;
}
.color {
  background-color: #1ab394;
}

/* 表格区域 */

.shut {
  margin-right: 250px;
}

/* 合同内容 */
.leftpactmatter {
  margin: 10px 0 0 100px;
}

/* 照片div */
.photo {
  display: inline-block;
  margin-left: 65px;
}
.pactbox4 p {
  font-size: 20px;
  margin-left: 60px;
  /* display: inline-block; */
}
.leftpactmatter1 {
  vertical-align: top;
  margin: 20px 0 0 80px;
}
.leftpactmatter2 {
  vertical-align: top;
  margin: 10px 0 0 100px;
}

.remarks {
  margin-left: 60px;
}
.usednumber {
  margin-left: 40px;
}
.el-upload {
  text-align: center;
}

.affix {
  display: inline-block;
  margin-left: 60px;
}
.affix span {
  color: gray;
  font-size: 18px;
}
a {
  text-decoration: underline;
  margin-right: 30px;
  font-size: 20px;
  color: #2cb4ff;
}
.compact {
  width: 1005px;
  height: 1000px;
}
.rightback {
  margin-right: 20px;
}

.son1,
.son2 {
  display: inline-block;
}
.son1 {
  vertical-align: top;
  margin-right: 60px;
}
.runmp {
  width: 100%;
  height: 200px;
  background-color: red;
}

.keydeposit {
  display: inline-block;
  margin-left: 20px;
  margin-right: 20px;
  height: 100%;
}
.block_ {
  /* display: inline-block; */
  float: left;
  margin-bottom: 20px;
  text-align: right;
}

.block_1 {
  /* float: left; */
  display: inline-block;
}
img {
  width: 80px;
  height: 80px;
  margin: 5px 10px;
}

.keepleft {
  display: inline-block;

  font-size: 20px;
  padding-left: 20px;
}
.keepright {
  position: relative;
  top: -30px;
  display: inline-block;
  margin-left: 120px;
}
.navBar {
  border-radius: 5px;
}
.nav_ > .navBar:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.nav_ > .navBar:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.nav_ > .navBar:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
</style>
